import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Slideshow(props) {
    const [activeIndex, setActiveIndex] = useState(null);

    // useEffect(() => {
    //     if (props.data != null)
    //         setActiveIndex(0);
    // }, [props.data])

    useEffect(() => {
        setActiveIndex(0);
    }, [])

    useEffect(() => {
        let timer = setTimeout(() => {
            setActiveIndex(getNext());
        }, 8000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex])

    let getNext = () => { return activeIndex + 1 >= slides.length ? 0 : activeIndex + 1; }
    let getPrev = () => { return activeIndex - 1 < 0 ? slides.length - 1 : activeIndex - 1; }
    // let getNext = () => { return activeIndex + 1 >= props.data.value.parameterMap.itemList.value.contentList.length ? 0 : activeIndex + 1; }
    // let getPrev = () => { return activeIndex - 1 < 0 ? props.data.value.parameterMap.itemList.value.contentList.length - 1 : activeIndex - 1; }

    const slides = props.data ? getValueFromParameterMap(props.data, 'slide-list').contentList : null;

    // const slides = [
    //     '/assets/images/slideshow/diploma.jpg',
    //     '/assets/images/slideshow/folyoson_emberek.jpg',
    //     '/assets/images/slideshow/gyerek_sarok.jpg',
    //     '/assets/images/slideshow/iroda_ember.jpg',
    //     '/assets/images/slideshow/pult_ember.jpg',
    //     '/assets/images/slideshow/csoportkep_2.jpg',
    // ]

    return (
        <div className={'relative w-full max-w-full aspect-[720/281] min-h-48 bg-gyeki-gray-lightest overflow-hidden ' + (props.className || '')}>

            {slides.map((item, index) => (<Slide key={index} data={item} active={index === activeIndex} />))}

            <div className='absolute w-full bottom-0 flex flex-row items-center justify-start sm:justify-end p-4 sm:p-6 gap-3 z-20'>
                {slides.map((item, index) => (<Orb key={index} data={item} active={index === activeIndex} onClick={() => { setActiveIndex(index) }} />))}
            </div>

            <div className='absolute bottom-0 left-0 w-full h-32 bg-gradient-to-t from-black/30 z-10' />

        </div>
    );
}

function Slide(props) {
    const image = getValueFromParameterMap(props.data, 'image') && getValueFromParameterMap(props.data, 'image').downloadUrl;

    return (
        <motion.div
            className={'absolute w-full h-full bg-center bg-no-repeat bg-cover grayscalee ' + (props.active ? 'z-10' : 'z-0')}
            style={{ backgroundImage: 'url(' + image + ')' }}
            initial={props.active ? 'visible' : 'hidden'}
            animate={props.active ? 'visible' : 'hidden'}
            transition={{ ease: 'easeInOut', duration: 1 }}
            variants={{ 'visible': { scale: 1, opacity: 1 }, 'hidden': { scale: 1.2, opacity: 0 } }}
        // variants={{ 'visible': { translateX: 0 }, 'hidden': { translateX: '-100%' } }}
        >

        </motion.div>
    );
}

function Orb(props) {
    return (
        <div className={'rounded-[3px] rotate-45 w-3 h-3 cursor-pointer transition shadow-xl border-2 border-white/50 ' + (props.active ? 'hover:border-white bg-white/40 ' : ' hover:border-white hover:bg-white/20')} onClick={() => props.onClick()} />
    );
}