
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react'

import MenuBlock from 'components/MenuBlock.js';
import MenuSlider from 'components/MenuSlider.js';

// import { getTranslation } from 'utils/DictionaryUtils.js';
import { getLanguage } from 'utils/DictionaryUtils.js';
import useWindowSize from 'hooks/useWindowSize';

export default function Header(props) {
    const [width] = useWindowSize();
    const [scroll, setScroll] = useState(false);
    const [sliderOpen, setSliderOpen] = useState(false);

    useEffect(() => {
        const onScroll = () => { setScroll(window.pageYOffset); };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => { if (width > 768) setSliderOpen(false); }, [width]);

    return (
        <>

            <header className={'w-full flex flex-row md:flex-col bg-gyeki-blue md:bg-transparent md:bg-gradient-to-t from-neutral-200/30 via-white to-white z-50 site-px ' + (props.className || '')}>

                <div className='absolute w-full block md:hidden'>
                    <MenuSlider className='flex md:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} />
                </div>

                <div className='md:w-full h-20 md:h-28 flex justify-center'>

                    <Link to='/' className={'md:transition duration-300 ' + (sliderOpen ? 'opacity-0 scale-75' : 'opacity-100 scale-100')}>
                        <img className='hidden md:block h-full pt-6' alt='logo' src={'/assets/images/logo-color-' + getLanguage() + '.svg'} />
                        <img className='block md:hidden h-full py-3' alt='logo' src={'/assets/images/logo-white-' + getLanguage() + '.svg'} />
                    </Link>

                </div>

                <div className='flex-1 h-20 flex justify-end md:justify-center'>

                    <MenuBlock className='hidden md:flex h-20' />

                    <div className={'h-20 items-center justify-center md:hidden z-50 flex ' + (sliderOpen ? 'fixed right-4' : '')}>
                        <Hamburger size={25} color="#ffffff" direction="left" toggled={sliderOpen} toggle={setSliderOpen} />
                    </div>

                </div>

            </header>

            <header className={'fixed w-full h-20 flex justify-between bg-neutral-200/80 shadow-3xl backdrop-blur-sm transition-all z-40 site-px ' + (scroll > 192 ? 'top-0' : '-top-20')}>

                <Link to='/'><img className='h-full py-3' alt='logo' src={'/assets/images/logo-' + getLanguage() + '.svg'} /></Link>

                <div className='h-full'>

                    <MenuBlock className='hidden lg:flex h-full' />

                    <div className={'flex h-full items-center justify-center lg:hidden z-50 ' + (sliderOpen ? 'fixed right-4' : 'block')}>
                        <Hamburger size={25} color="#595959" direction="left" toggled={sliderOpen} toggle={setSliderOpen} />
                    </div>

                </div>

            </header>

        </>
    )
}