/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MenuLister from 'elements/MenuLister.js';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function LeftMenu(props) {
    const [data, setData] = useState(null);

    let location = useLocation();

    const getDefaultMenu = (currentLocation) => {
        let pathName = currentLocation.pathname;
        const pathParts = pathName.substring(1).split('/');
        const basePath = pathParts[0];
        const mainMenu = getValueFromParameterMap(getWebsite().globalContent, 'menu');
        let baseMenu = mainMenu;
        for (let i = 0; i < mainMenu.itemList.length; i++)
            if (mainMenu.itemList[i].path === basePath)
                baseMenu = mainMenu.itemList[i]
        return baseMenu.childList ? baseMenu : null;
    }

    useEffect(() => {
        if (props.data && props.data.value != null)
            setData(props.data.value)
        else if (location && getValueFromParameterMap(getWebsite().globalContent, 'menu'))
            setData(getDefaultMenu(location));
    }, [props.data, location])

    return (
        <div className={' ' + (props.className || '')}>

            {data && <div className='flex flex-col gap-4 '>

                <div className='font-display text-sm font-semibold'>{data.title}</div>

                <MenuLister
                    className='w-full pb-6 mb-6 border-b border-neutral-100'
                    data={data}
                    baseLevel={1}
                    // baseLevel={0}
                    // firstLevelKey='itemList'
                    firstLevelKey={(props.data && props.data.value) ? 'itemList' : 'childList'}
                    activeClassName=''
                    baseClassName='text-xs'
                    mainClassName=''
                    subClassName='' />

            </div>}

        </div>
    );
}