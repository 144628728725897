const dictionaryConfig = {
    'title': {
        'hu-HU': 'Dr. Gyimóthy Eszter Közjegyzői Irodája',
        'en-GB': 'Dr. Gyimóthy Eszter’s Notary Office'
    },
    'motto': {
        'hu-HU': 'Törvényesség. Pontosság. Innováció.',
        'en-GB': 'Lawfulness - Accuracy - Innovation'
    },
    'motto-part-1': {
        'hu-HU': 'Törvényesség.',
        'en-GB': 'Lawfulness'
    },
    'motto-part-2': {
        'hu-HU': 'Pontosság.',
        'en-GB': 'Accuracy'
    },
    'motto-part-3': {
        'hu-HU': 'Innovation.',
        'en-GB': 'Innovation'
    },
    'copyright': {
        'hu-HU': 'Minden jog fenntartva.',
        'en-GB': 'All rights reserved.'
    },
    'contact': {
        'hu-HU': 'Kapcsolat',
        'en-GB': 'Contact'
    },
    'seat-title': {
        'hu-HU': 'székhely',
        'en-GB': 'seat'
    },
    'seat-number': {
        'hu-HU': '3.',
        'en-GB': 'No. 3.'
    },
    'jurisdiction': {
        'hu-HU': 'Illetékességi terület',
        'en-GB': 'Jurisdiction'
    },
    'seat-code': {
        'hu-HU': 'Székhelykód',
        'en-GB': 'Seat code'
    },
    'cookie-settings': {
        'hu-HU': 'Cookie beállítás',
        'en-GB': 'Cookie settings'
    },
    'cookie-description': {
        'hu-HU': 'Honlapunk megfelelő működése érdekében Cookie-knak nevezett adatfájlokat (magyarul: sütiket) helyezünk el számítógépén, amelyek javítják a felhasználói élményt és biztosítják az oldal megfelelő működését.',
        'en-GB': 'In order for our website to work properly, like most websites, we sometimes place data files (cookies) on your computer, which improves the user experience and ensures the proper functioning of the site.'
    },
    'cookie-information': {
        'hu-HU': 'További információk',
        'en-GB': 'More information'
    },
    'cookie-accept': {
        'hu-HU': 'Elfogadom',
        'en-GB': 'Accept'
    },
}

export default dictionaryConfig;