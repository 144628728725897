import globalConfig from 'config/globalConfig.js';
import { getLanguage } from 'utils/DictionaryUtils.js';

export default function LinkBar(props) {
    return (
        <div className={'flex flex-col text-lg lg:text-sm text-center lg:text-left divide-neutral-100 divide-y ' + (props.className || '')}>

            {globalConfig.linkListMap[getLanguage()].map((item, index) => (<Item key={index} data={item} />))}

        </div>
    )
}

function Item(props) {
    return (
        <div className='flex flex-col gap-6 lg:gap-4 py-6 first:pt-0 last:pb-0'>
            <div className='font-display font-bold'>{props.data.title + ''}</div>
            <div className='flex flex-col gap-4'>
                {props.data.list.map((subItem, subIndex) => (
                    <div key={subIndex} className='flex flex-col gap-2 sm:gap-4 lg:gap-2'>
                        <a href={subItem.url} className='text-sm lg:text-xs hover:text-gyeki-gold transition-colors'>{subItem.title}</a>
                    </div>
                ))}
            </div>
        </div>
    );
}