const globalConfig = {
    'domainName': 'gyeki.hu',
    'etc': {
        'cookiePerfix': '_gyeki',
        'officeEmail': 'iroda@gyeki.hu',
        'privacyPolicyPath': '/adatkezeles',
        'cookiePolicyPath': {
            'hu-HU': '/cookie-tajekoztato',
            'en-GB': '/cookie-information'
        }
    },
    'linkListMap': {
        "hu-HU": [
            {
                'title': 'Közjegyzői elektronikus rendszerek',
                'list': [
                    { 'title': 'Fizetési meghagyásos eljárás', 'url': 'https://fmh.mokk.hu/' },
                    { 'title': 'Hitelbiztosítéki Nyilvántartás', 'url': 'https://hbny.mokk.hu/' },
                    { 'title': 'Közjegyzői hirdetmények', 'url': 'https://hirdetmenyek.mokk.hu/' },
                ]
            },
            {
                'title': 'Elektronikus ügyintézés',
                'list': [
                    { 'title': 'Tájékoztató az elektronikus ügyintézésről', 'url': 'https://mokk.hu/hasznos-informaciok/e-ugyintezes/' },
                ]
            },
            {
                'title': 'Űrlapok',
                'list': [
                    { 'title': 'Papíralapú űrlapok', 'url': 'https://mokk.hu/ugyfeleknek/kerelmek.php' },
                    { 'title': 'Elektronikus űrlapok', 'url': 'https://mokk.hu/kerelmek-urlapok/elektronikus-urlapok-anyk/' },
                ]
            },
            {
                'title': 'Adatvédelem',
                'list': [
                    { 'title': 'Tájékoztató a közjegyzői hatáskörbe tartozó eljárásokkal összefüggésben megvalósuló adatkezelésekről', 'url': 'https://mokk.hu/wp-content/uploads/2023/06/Tajekoztato-a-kozjegyzoi-hataskorbe-tartozo-eljarasokkal-osszefuggesben-....pdf' },
                ]
            }
        ],
        "en-GB": [
            {
                'title': 'Notarial electronic systems',
                'list': [
                    { 'title': 'Order for payment', 'url': 'https://fmh.mokk.hu/' },
                    { 'title': 'Security Interest Register', 'url': 'https://hbny.mokk.hu/' },
                    { 'title': 'Notarial notices', 'url': 'https://hirdetmenyek.mokk.hu/' },
                ]
            },
            {
                'title': 'Electronic administration',
                'list': [
                    { 'title': 'Information on electronic administration', 'url': 'https://mokk.hu/ugyfeleknek/tajekoztato-az-elektronikus-ugyintezesrol.php' },
                ]
            },
            {
                'title': 'Forms',
                'list': [
                    { 'title': 'Paper forms', 'url': 'https://mokk.hu/ugyfeleknek/kerelmek.php' },
                    { 'title': 'Electronic forms', 'url': 'https://mokk.hu/ugyfeleknek/elektronikus-urlapok.php' },
                ]
            },
            {
                'title': 'Data protection',
                'list': [
                    { 'title': 'Information on data processing in the context of procedures falling within the competence of notaries', 'url': 'https://mokk.hu/regioldal/pdf/linkgyujto/kozadat/tajekoztato_kjoi_eljarasok.pdf' },
                ]
            }
        ]
    },
    'mapStlyes': [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e9e9e9"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#333333"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f2f2f2"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#fefefe"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        }
    ]
}

export default globalConfig;