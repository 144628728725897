import { Link } from 'react-router-dom';

import { getWebsite } from 'utils/WebsiteUtils.js';
import { getTranslation } from 'utils/DictionaryUtils.js';
import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function Footer(props) {
    let _data = {
        menu: getValueFromParameterMap(getWebsite().footerContent, 'menu'),
    };

    return (
        <footer id='footer' className='bg-gyeki-blue flex items-center justify-center'>

            <div className='w-full py-8 flex flex-col md:flex-row items-center justify-center text-white site-px gap-6 md:gap-0'>

                <div className='flex-1 flex flex-col items-center gap-6 sm:gap-2'>

                    <div className='flex-1 flex flex-col lg:flex-row items-center md:items-start text-sm text-center md:text-left order-last md:order-none'>
                        <div className='flex flex-row'>
                            <div>{new Date().getFullYear() + ' ©'}<span>&nbsp;</span></div>
                            <div className='block lg:hidden'>{getTranslation('copyright')}</div>
                        </div>
                        <div className='flex flex-row'>
                            <div>{getTranslation('title')}</div>
                            <div className='hidden lg:block'><span>&nbsp;</span>{'- ' + getTranslation('copyright')}</div>
                        </div>
                    </div>

                    {(_data.menu && _data.menu.itemList) && <div className='flex flex-row items-center justify-center sm:justify-start divide-x-2 divide-white divide-opacity-25'>
                        {_data.menu.itemList.map((item) => (<Link key={item.key} to={item.fullPath} className='font-alternative font-bold leading-none text-xs px-1.5 first:pl-0 last:pr-0 hover:underline underline-offset-1'>{item.title}</Link>))}
                    </div>}

                </div>

            </div>

        </footer>
    )
}